import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
    CBadge,
    // CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CInput,
    CLabel,
    CRow,
    // CButton,
} from "@coreui/react";
import "./styles.css";
// import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { HiDocumentText } from "react-icons/hi";
import { UserContext, sideBarContext } from "src/UserContext";
import { TiDeleteOutline } from "react-icons/ti";
import moment from "moment";
import { IoIosSend } from "react-icons/io";
import Loading from "./componants/Loading";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "src/redux-toolkit/auth";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { CPagination } from "@coreui/react";
// const getBadge = (status) => {
//     switch (status) {
//         case "Active":
//             return "success";
//         case "Inactive":
//             return "secondary";
//         case "pending":
//             return "warning";
//         case "rejected":
//             return "danger";
//         default:
//             return "primary";
//     }
// };
const fields = [
    "name",
    "email",
    "phone",
    "resume",
    "experience",
    // "remark",
    "Blacklisted",
    "state_&_city",
    "DOB",
    "gender",
    "marital_status",
    "notice_period",
    "CCTC",
    "ECTC",
    "created_on",
    "last_updated_on",
];
const ViewProfile = () => {
    const { setSideBar } = useContext(sideBarContext);
    useEffect(() => {
        if (window.innerWidth < 1001) {
            setSideBar(false);
        }
    }, []);
    const [data, setData] = useState([]);
	const [totalData, setTotalData] = useState(0);
	const [totalFilteredData, setTotalFilteredData] = useState(0);
    const [load, setLoad] = useState(true);
    const [view, setView] = useState(true);
    const [modal, setModal] = useState(false);
    const { path, setPath } = useContext(UserContext);
    const [searchText, setSearchText] = useState('');

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // const [searchFlag, setSearchFlag] = useState(false);
    // const dispatch=useDispatch()
    let value = useSelector((state) => {
        return state.persist;
    });
    let dispatch = useDispatch();
    const setauth = () => {
        dispatch(setToken("this new token"));
    };

	let getData = (page) => {
		setLoad(true);
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/getalluser/${itemsPerPage}/${page ? page : currentPage}${searchText ? `?q=${searchText}` : ''}`, {
			headers: { authorization: localStorage.getItem("token") },
		}).then((res) => {
			if (res) {
				if (res.data.token) {
					alert("reloading");
					localStorage.removeItem("token");
					// window.location.reload();
				} else {
					setData(res.data.data.list);
					setTotalData(res.data.data.total);
					setTotalFilteredData(res.data.data.filtered);
					setLoad(false);
				}
			}
		}).catch((err) => {
			setLoad(false);
		});
	}

    useEffect(() => {
        console.log(value);
        setPath("Candidates/View Candidates");
    }, []);

    useEffect(() => {
        getData(1);
    }, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCurrentPage(1);
			getData(1)
		}, 1000);

		return () => {
			clearTimeout(timer);
		}
	}, [searchText])
    // function changeStatus(status, id) {
    //     axios
    //         .patch(
    //             `${process.env.REACT_APP_API_BASE_URL}/candidates/changeStatus`,
    //             { status, id },
    //             {
    //                 headers: { authorization: localStorage.getItem("token") },
    //             }
    //         )
    //         .then((response) => {
    //             if (response) {
    //                 if (response.data.token) {
    //                     localStorage.setItem("token", "token");
    //                     localStorage.removeItem("refreshToken");
    //                     window.location.reload();
    //                 }
    //             }
    //         });
    // }

    const history = useHistory();

	let timer = null;
	let text = '';

    return (
        <>
            {load ? (
                <Loading value={true}></Loading>
            ) : (
                <CRow>
                    <CCol>
                        <CCard>
                            <CCardHeader>
                                <CRow>
                                    <CCol md="8 d-flex align-items-center">
                                        <CLabel className={"h5 my-auto"}>All Candidates - {totalFilteredData}/{totalData}</CLabel>
                                
                                    </CCol>
                                    <CCol xs="10" sm="8" md="4 my-1 d-flex align-items-center">
                                        {/* {localStorage.getItem("role") !== "evaluator" && (
                                            <CButton
                                                onClick={() => {
                                                    history.push({
                                                        pathname: "/pages/addjd",
                                                    });
                                                }}
                                                size="sm"
                                                color="primary"
                                                className="float-right"
                                            >
                                                Add New JD
                                            </CButton>
                                        )} */}
                                        <CLabel className={"m-auto p-0"}>Search:</CLabel>
                                        <CInput
                                            onChange={(e) => {
                                                text = e.target.value.trimStart();
												setSearchText(text);

												// console.log('clearing timer', timer);
												// if(timer) {
												// 	clearTimeout(timer);
												// 	timer = null;
												// }
													
												// timer = setTimeout(() => {
												// 	timer = null;
												// 	setCurrentPage(1);
												// 	getData(1)
												// }, 3000);

												// console.log('clearing timer 1', timer);

                                                /*let searchData = data.filter((item) => {
                                                    
                                                    const regex = new RegExp(e.target.value, "i");
                                                    
                                                    if (regex.test(item.name)) {
                                                        return item;
                                                    }

                                                    if (item.email.startsWith(text)) {
                                                        return item;
                                                    } else if (
                                                        item.name
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    } else if (
                                                        item.state
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    } else if (
                                                        item.city
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    } else if (item.phone.toString().startsWith(text)) {
                                                        return item;
                                                    } else if (
                                                        moment(item.dob)
                                                            .format("DD-MMM-YYYY")
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    } else if (
                                                        item.notice_periode.toString().startsWith(text)
                                                    ) {
                                                        return item;
                                                    } else if (
                                                        moment(item.createdAt)
                                                            .format("DD-MMM-YYYY")
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    } else if (
                                                        moment(item.updatedAt)
                                                            .format("DD-MMM-YYYY")
                                                            .toLowerCase()
                                                            .startsWith(text.toLowerCase())
                                                    ) {
                                                        return item;
                                                    }
                                                });*/
                                                
												// setSearch(searchData);
                                                // if (!searchData[0] && e.target.value) {
                                                //     setSearchFlag(true);
                                                // } else {
                                                //     setSearchFlag(false);
                                                // }
                                            }}
                                            placeholder="Search Candidate"
                                            className={"col-8    ml-auto"}
											value={searchText}
                                        ></CInput>
                                    </CCol>
                                </CRow>
                            </CCardHeader>
                            <CCardBody style={{ overflowX: "scroll" }}>
                                <CPagination
                                    activePage={currentPage}
                                    limit={9}
                                    dots={false}
                                    pages={Math.floor(totalFilteredData / itemsPerPage) + (totalFilteredData % itemsPerPage == 0 ? 0 : 1)}
                                    onActivePageChange={(i) => {setCurrentPage(i); getData(i);}}
                                />
                                <div style={{ minWidth: "1600px" }}>
                                    <CDataTable
                                        items={data}
                                        // items={data.slice(currentPage*itemsPerPage, (currentPage+1)*itemsPerPage)}
                                        fields={fields}
                                        hover
                                        responsive
                                        bordered
                                        size="sm"
                                        // sorter={true}
                                        // tableFilter={true}
                                        //pagination={{onActivePageChange: (a)=>{setCurrentPage(a); return true;}, activePage: {currentPage}}}
                                        // clickableRows
                                        // onRowClick={(event) => {
                                        //     if (view) {
                                        //         history.push({
                                        //             pathname: "/pages/detailprofile",
                                        //             state: event,
                                        //         });
                                        //     }
                                        // }}
                                        scopedSlots={{
                                            created_on: (item) => {
                                                return (
                                                    <td>
                                                        {moment(item.createdAt).format("DD-MMM-YYYY")}
                                                    </td>
                                                );
                                            },
                                            Blacklisted: (item) => {
                                                return (
                                                    <td>
                                                        {/* <Form.Switch
                                                            // prettier-ignore
                                                        checked={item.blacklisted}
                                                            style={{cursor:"pointer"}}
                                                            className="text-danger"
                                                            type="switch"
                                                            id={item.id}
                                                            feedbackTooltip={true}
                                                            feedback="ok"
                                                        /> */}
                                                        {item.blacklisted?<CBadge className={"bg-danger"}>Blacklisted</CBadge>:""}
                                                    </td>
                                                );
                                            },
                                            "state_&_city": (item) => {
                                                return (
                                                    <td>
                                                        {item.state}-{item.city}
                                                    </td>
                                                );
                                            },
                                            DOB: (item) => {
                                                return (
                                                    <td>{moment(item.dob).format("DD-MMM-YYYY")}</td>
                                                );
                                            },
                                            gender: (item) => {
                                                return <td>{item.gender}</td>;
                                            },
                                            notice_period: (item) => {
                                                return (
                                                    <td>
                                                        {item.notice_periode == "0"
                                                            ? "Immediately "
                                                            : item.notice_periode + " days"}{" "}
                                                    </td>
                                                );
                                            },
                                            experience: (item) => {
                                                return <td>{item.experience ? item.experience > 1 ? item.experience+" years": item.experience+" year":    "NA"}</td>;
                                            },
                                            CCTC: (item) => {
                                                return <td>{item.current_ctc}</td>;
                                            },
                                            ECTC: (item) => {
                                                return <td>{item.expected_ctc}</td>;
                                            },
                                            last_updated_on: (item) => {
                                                return (
                                                    <td>
                                                        {moment(item.updatedAt).format("DD-MMM-YYYY") +
                                                            "-" +
                                                            moment(item.updatedAt).format("LT")}
                                                    </td>
                                                );
                                            },
                                            name: (item) => {
                                                return (
                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        className="text-primary"
                                                        // onClick={() => {
                                                        //     history.push({
                                                        //         pathname: `/pages/detailprofile`,
                                                        //         state: item,
                                                        //     });
                                                        // }}
                                                        // `onClick={setauth}`
                                                    >
                                                        <Link
                                                            to={`/home/pages/detailprofile?id=${item.id}`}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    </td>
                                                );
                                            },
                                            resume: (item) => {
                                                return (
                                                    <td
                                                        onClick={() => {
                                                            if (item.resume) {
                                                                window.open(
                                                                    `${process.env.REACT_APP_API_BASE_URL}/documentView/?q=${item.resume}`,
                                                                    "_blank"
                                                                );
                                                            }
                                                        }}
                                                        className="text-primary pl-4"
                                                        style={{
                                                            cursor: item.resume ? "pointer" : "default",
                                                        }}
                                                    >
                                                        {item.resume ? (
                                                            <HiDocumentText size={20}></HiDocumentText>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                );
                                            },
                                            Current_status: (item) => {
                                                const str = item.status;
                                                // const str2 = str.charAt(0).toUpperCase() + str.slice(1);
                                                return (
                                                    <td
                                                        onMouseEnter={() => {
                                                            setView(false);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setView(true);
                                                        }}
                                                    >
                                                        {/* {str2} */}
                                                        <option
                                                            className="text-dark"
                                                            hidden={item.status !== "Added" ? true : false}
                                                        >
                                                            Added
                                                        </option>
                                                        <option
                                                            className="text-dark"
                                                            hidden={item.status !== "Applied" ? true : false}
                                                        >
                                                            Applied
                                                        </option>
                                                        <option
                                                            className="text-success"
                                                            hidden={item.status !== "Selected" ? true : false}
                                                        >
                                                            Selected
                                                        </option>
                                                        <option
                                                            className="text-danger"
                                                            hidden={item.status !== "Rejected" ? true : false}
                                                        >
                                                            Rejected
                                                        </option>
                                                        <option
                                                            className="text-warning"
                                                            hidden={
                                                                item.status !== "Inprocess" ? true : false
                                                            }
                                                        >
                                                            Inprocess
                                                        </option>
                                                        <option
                                                            className="text-info"
                                                            hidden={item.status !== "Joined" ? true : false}
                                                        >
                                                            Joined
                                                        </option>
                                                        <option
                                                            className="text-danger"
                                                            hidden={
                                                                item.status !== "Offer Rejected" ? true : false
                                                            }
                                                        >
                                                            Offer Rejected
                                                        </option>
                                                        <option
                                                            className="text-danger"
                                                            hidden={item.status !== "Resigned" ? true : false}
                                                        >
                                                            Resigned
                                                        </option>
                                                        {/* <AiOutlineEye size={20}    onClick={()=>history.push({
                                                    pathname:'/pages/detailprofile',
                                                    state:item
                                            })}/> */}
                                                        {/* <select
                                                    class="custom-select custom-select-sm m-0"
                                                    style={{outlineColor:"white",borderColor:"white",fontSize:"16px"}}
                                                >
                                                    <option className="text-muted" selected>Registered</option>
                                                    <option className="text-warning" value="1">Inprocess</option>
                                                    <option className="text-success" value="2">Selected</option>
                                                    <option className="text-danger" value="3">Rejected</option>
                                                    <option className="text-primary" value="3">Joined</option>
                                                </select> */}

                                                        {/* <CRow onClick={()=>{
                                                );

                                                }} style={{cursor:"pointer"}}>{data.application_status} </CRow> */}
                                                    </td>
                                                );
                                            },
                                            remark: (item) => {
                                                return (
                                                    <td
                                                        onMouseEnter={() => {
                                                            setView(false);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setView(true);
                                                        }}
                                                        onClick={() => {
                                                            setModal(true);
                                                        }}
                                                    >
                                                        {/* <AiOutlineEye
                                                        size={20}
                                                        className={"mr-1"}
                                                        onClick={
                                                            () =>{
                                                            history.push({
                                                                pathname: "/pages/detailprofile",
                                                                state: item,
                                                            })}
                                                        }
                                                    /> */}
                                                        {/* <AiOutlineEdit
                                                        size={20}
                                                        className={"mx-1"}
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: "/pages/candidates/ViewProfile",
                                                                state: item,
                                                            })
                                                        }
                                                    />
                                                    <AiOutlineDelete
                                                        size={20}
                                                        className={"mx-1"}
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: "/pages/detailprofile",
                                                                state: item,
                                                            })
                                                        }
                                                    /> */}
                                                        <Remark data={item}></Remark>
                                                    </td>
                                                );
                                            },
                                            email: (item) => {
                                                return (
                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        className="text-primary"
                                                        // onClick={() => {
                                                        //     history.push({
                                                        //         pathname: `/pages/detailprofile`,
                                                        //         state: item,
                                                        //     });
                                                        // }}
                                                        // `onClick={setauth}`
                                                    >
                                                        <a href={`mailto:${item.email}`} target="_blank">
                                                            {item.email}
                                                        </a>
                                                    </td>
                                                );
                                            },
                                            phone: (item) => {
                                                return (
                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        className="text-primary"
                                                        // onClick={() => {
                                                        //     history.push({
                                                        //         pathname: `/pages/detailprofile`,
                                                        //         state: item,
                                                        //     });
                                                        // }}
                                                        // `onClick={setauth}`
                                                    >
                                                        <a href={`tel:${item.phone}`} target="_blank">
                                                            {item.phone}
                                                        </a>
                                                    </td>
                                                );
                                            },
                                        }}
                                    />
                                </div>
                                
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            )}
        </>
    );
};

export default ViewProfile;

// function Remark(props) {
//     const [show, setShow] = useState(false);
//     const [remark, setRemark] = useState(props.data.remark);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     function addRemark(e) {
//         axios
//             .post(
//                 `${process.env.REACT_APP_API_BASE_URL}/candidates/addRemark`,
//                 { remark, id: props.data.id },
//                 {
//                     headers: { authorization: localStorage.getItem("token") },
//                 }
//             )
//             .then((response) => {
//                 handleClose();
//             });
//     }
//     return (
//         <>
//             {/* <Button variant="primary">
//                 Launch demo modal
//             </Button> */}
//             <svg
//                 onClick={handleShow}
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="25"
//                 height="25"
//                 fill="currentColor"
//                 className={remark ? "bi bi-card-text text-info" : "bi bi-card-text"}
//                 viewBox="0 0 16 16"
//             >
//                 <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
//                 <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
//             </svg>
//             <Modal centered backdrop="static" show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Remark</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form.Control
//                         value={remark}
//                         onChange={(e) => {
//                             setRemark(e.target.value);
//                         }}
//                         as="textarea"
//                         rows={5}
//                     />
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button
//                         variant="primary"
//                         onClick={(e) => {
//                             addRemark(e);
//                         }}
//                     >
//                         Save Changes
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// }
function Remark(props) {
    const [show, setShow] = useState(false);
    // const [remark, setRemark] = useState(props.data.evaluation_remark);
    const [remark, setRemark] = useState(props.data.remark);
    const [text, setText] = useState("");
    const [scroll, setScroll] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    });
    const deleteRemark = (target) => {
        let editedRemark = remark.filter((obj, index) => {
            if (index !== target) {
                return obj;
            }
        });

        setRemark(editedRemark);
        scrollToBottom();
        setScroll(false);
        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/candidates/deleteCandidateRemark`,
                {
                    remark: editedRemark,
                    id: props.data.id,
                    user: localStorage.getItem("id"),
                },
                {
                    headers: { authorization: localStorage.getItem("token") },
                }
            )
            .then((response) => {
                // console.log(response.data.evaluation_remark);
                setRemark(response.data.remark);
                setText("");
                // handleClose();
            });
    };
    function addRemark(newRemark) {
        scrollToBottom();
        setScroll(false);
        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/candidates/addRemark`,
                {
                    remark: [...remark, newRemark],
                    id: props.data.id,
                    user: localStorage.getItem("id"),
                },
                {
                    headers: { authorization: localStorage.getItem("token") },
                }
            )
            .then((response) => {
                // console.log(response.data.evaluation_remark);
                setRemark(response.data.remark);
                setText("");
                // handleClose();
            });
    }
    return (
        <>
            {/* <Button variant="primary">
                    Launch demo modal
                </Button> */}
            <svg
                style={{ cursor: "pointer" }}
                onClick={handleShow}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className={remark[0] ? "bi text-primary " : "bi bi-card-text"}
                viewBox="0 0 16 16"
            >
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
            </svg>
            <Modal centered backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Remark</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="w-100    flex-column rounded p-1 mb-2"
                        style={{
                            height: "auto",
                            maxHeight: "300px",
                            backgroundColor: "#ffffff",
                            overflowY: "scroll",
                            display: "inline-block",
                        }}
                    >
                        {remark.map((obj, index) => {
                            let str2 = "";
                            if (obj.role) {
                                const str = obj.role;
                                str2 = str.charAt(0).toUpperCase() + str.slice(1);
                            }

                            return (
                                <div className="w-100 d-flex">
                                    <div
                                        className={
                                            localStorage.getItem("id") === obj.userId
                                                ? "mb-2 px-1 rounded ml-auto"
                                                : "mb-2 px-1 rounded"
                                        }
                                        style={{
                                            backgroundColor:
                                                localStorage.getItem("id") === obj.userId
                                                    ? "#e9f5db"
                                                    : "#dee2ff",
                                            minWidth: "70%",

                                            maxWidth: "80%",
                                        }}
                                        key={index}
                                    >
                                        <div className=" d-flex justify-content-between align-items-center">
                                            <small className=" text-primary ">
                                                {obj.user}-{str2}
                                            </small>

                                            {localStorage.getItem("role") === "admin" ? (
                                                <TiDeleteOutline
                                                    className="cross"
                                                    onClick={() => {
                                                        deleteRemark(index);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                    size={19}
                                                ></TiDeleteOutline>
                                            ) : localStorage.getItem("id") === obj.userId ? (
                                                <TiDeleteOutline
                                                    className="cross"
                                                    onClick={() => {
                                                        deleteRemark(index);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                    size={19}
                                                ></TiDeleteOutline>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <p className=" mb-0 mt-0 pl-1 pt-0 ">
                                            <small
                                                className="mt-0 pl-1 pt-0"
                                                style={{ color: "#231942" }}
                                            >
                                                {obj.remark}
                                            </small>
                                        </p>
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                width: "auto",
                                            }}
                                        >
                                            <small
                                                style={{ fontSize: ".7rem" }}
                                                className="mt-0 text-end ml-auto"
                                            >
                                                {moment(obj.date).startOf().fromNow()}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            style={{ width: "100%", height: "2px" }}
                            ref={messagesEndRef}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex ">
                    <CRow className={" w-100"}>
                        <CCol className={"col-10 col-sm-10 p-0 "}>
                            <Form.Control
                                value={text}
                                onChange={(e) => {
                                    setText(e.target.value.trimStart());
                                }}
                                as="textarea"
                                rows={1}
                            />
                        </CCol>

                        {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                        <CCol className={"col-sm-1"}>
                            <Button
                                disabled={text ? false : true}
                                variant="primary"
                                onClick={(e) => {
                                    setScroll(true);
                                    let date = Date.now();
                                    if (remark[0]) {
                                        setRemark((obj) => {
                                            // let date= new Date()
                                            return [
                                                ...obj,
                                                {
                                                    date: date,
                                                    remark: text,
                                                    user: localStorage.getItem("id"),
                                                    userId: localStorage.getItem("id"),
                                                    role: localStorage.getItem("role"),
                                                },
                                            ];
                                        });
                                    } else {
                                        setRemark([
                                            {
                                                date: date,
                                                remark: text,
                                                user: localStorage.getItem("id"),
                                                userId: localStorage.getItem("id"),
                                                role: localStorage.getItem("role"),
                                            },
                                        ]);
                                    }

                                    addRemark({
                                        date: date,
                                        remark: text,
                                        user: localStorage.getItem("id"),
                                        userId: localStorage.getItem("id"),
                                        role: localStorage.getItem("role"),
                                    });
                                }}
                            >
                                <IoIosSend size={22}></IoIosSend>
                            </Button>
                        </CCol>
                    </CRow>
                </Modal.Footer>
            </Modal>
        </>
    );
}
